import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VariablesEnum } from '../enum/variables.enum';
import { LocalStorageService } from '../services/localstorage/localstorage.service';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,

  ) {
  }

  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    location: Location;


    //CODIGO PARA REDIRIGIR A HTTPS
    if(environment.production){
      if(location.protocol === 'http:' ){
        window.location.href = location.href.replace('http','https');
      }
    } 

    const session = this.localStorageService.getJsonValue(VariablesEnum.usuario_conade);

    if (route.routeConfig.path === 'autenticacion') {

      if (session) {

        this.router.navigate(['/conade/inicio']);

        return false;
      }
      return true;

    } else {

      if (session) {

        return true;

      }

      this.router.navigate(['/conade/autenticacion']);

      return false;
    }
  }

}
