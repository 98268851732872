export enum RolesEnum {
  COORDINADOR_EJECUTIVO = 1,
  ASISTENTE = 2,
  RECEPCION = 3,
  COLABORADOR = 4,
  JEFE_DEPARTAMENTO = 5,
  DIRECTOR = 6,
  SUBDIRECTOR = 7,
  SUPERVISOR = 8,
  ADMINISTRADOR = 10
}
