import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { AsuntoModel, OFP_ASUNTOARCHIVOS } from '../../models/asunto.model';

@Injectable({
  providedIn: 'root'
})
export class AsuntoService extends MainService {

  constructor(public http: HttpClient) {
    super(http);
  }

  consultar(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_/Consultar' + filtro);
  }

  consultarAsuntosUsuario(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/BUS_OFP_ASUNTO_/Consultar' + filtro);
  }

  consultarAsuntosTableroDireccion(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/TABLERO_DIRECCION_ASUNTO_/Consultar' + filtro);
  }

  consultarxRolUsuario(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/VIEW_OFP_ASUNTO_/ConsultarPaginacion' + filtro);
  }

  consultarBusqueda(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/VIEW_OFP_ASUNTO_/ConsultarPaginacionBusqueda' + filtro);
  }

  consultarIds(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/VIEW_OFP_ASUNTO_/ConsultarId/' + filtro);
  }
  consultarIdsBusqueda(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/VIEW_OFP_ASUNTO_/ConsultarIdBusqueda/' + filtro);
  }
  
  consultarxRolUsuarioEnlace(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/VIEW_OFP_ASUNTO_/ConsultarEnlaces' + filtro);
  }

  consultarxMes(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + "Asunto/VIEW_OFP_ASUNTO_MES_/Consultar", objeto);
  }


  operacion(objeto: AsuntoModel) {
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_/Agregar', objeto);
  }
  agregarArchivo(objeto: Array<OFP_ASUNTOARCHIVOS>) {
    return this.postAsync(this.gatewayOficialiaPartesURL + 'CARGARCHIVOS/OFP_CARGARCHIVOS_/AgregarAdjunto', objeto);
  }

  consultarArchivosAsunto(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/SP_OFP_ASUNTO_ARCHIVOS_/Consultar' + filtro);
  }

  modificar(objeto: AsuntoModel) {
    return this.putAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_/Actualizar', objeto);
  }

  eliminar(objeto: AsuntoModel) {
    return this.deleteAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_/Eliminar');
  }

  generarCodigo(objeto: any) {
    return this.postAsync(this.gatewayOficialiaPartesURL + 'CARGARCHIVOS/OFP_CARGARCHIVOS_/GenerarCodigo/' + objeto, null);
  }

  agregarAsuntoUsuario(objeto: AsuntoModel, usuario: string) {
    return this.postHeaderAsync(this.gatewayOficialiaPartesURL + 'Asunto/Asuntos/Agregar', objeto, usuario);
  }

  asuntoVisto(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_VISTO_/Agregar', objeto);
  }

  eliminarArchivo(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ELIMINACION_ARCHIVOS_/Eliminar', objeto);
  }

  eliminarArchivoCreacion(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ELIMINACION_ARCHIVOS_/EliminarCreacion', objeto);
  }

  consultarxRolUsuarioRecepcion(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_RECEPCION_/Consultar' + filtro);
  }

  consultarSubdirecciones(filtro: string){
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/SUBDIRECCIONES_/Consultar' + filtro);
  }

  validarFolioInterno(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/VALIDAR_FOLIO_INTERNO_ASUNTO_/Validar' + filtro);
  }

  consultarProcedencia(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Catalogos/OFP_CAT_PROCEDENCIA_/Consultar' + filtro);
  }

  generarLinkReporteControl(filtro: string){
    return (this.gatewayOficialiaPartesURL + 'Asunto/REPORTE_ASUNTO_/Generar' + filtro);
  }

  dirigidosConsulta(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_FOLIO_DIRIGIDO_/Consultar', objeto)
  }

  dirigidosInsetar(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_FOLIO_DIRIGIDO_/Insertar', objeto)
  }

  dirigidosEditar(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_FOLIO_DIRIGIDO_/Editar', objeto)
  }

  consultarEtiquetas(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_TAG_ASUNTO_/Consultar' + filtro);
  }

  consultarEtiquetasAsunto(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_TAG_ASUNTO_/ConsultarTagAsunto' + filtro);
  }

  agregarEtiqueta(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_TAG_ASUNTO_/Agregar', objeto)
  }

  agregarEtiquetaAsunto(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_TAG_ASUNTO_/AgregarTagAsunto', objeto)
  }

  ConsultarEnlacesTitular(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_TITULAR_ENLACE_/ConsultarEnlacesTitular' + filtro);
  }

  ConsultarEnlacesDisponibles(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_TITULAR_ENLACE_/ConsultarEnlacesDisponibles' + filtro);
  }

  ConsultarTitular(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_TITULAR_ENLACE_/ConsultarTitular' + filtro);
  }

  AgregarEnlace(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_TITULAR_ENLACE_/Agregar', objeto)
  }

  EliminarEnlace(objeto: any){
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_TITULAR_ENLACE_/Eliminar', objeto)
  }



}
