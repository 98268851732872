<!--Start topbar header-->
<header class="topbar-nav">
  <nav id="header-setting" class="navbar navbar-expand  bg-white navbar-static-top">
    <ul class="navbar-nav mr-auto align-items-center">
      <li class="nav-item">
        <a class="nav-link toggle-menu" (click)="toggleSidebar()">
          <i class="icon-menu menu-icon"></i>
        </a>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center right-nav-link">
      <li class="nav-item border-left" ngbDropdown placement="bottom-right">
        <a
          href="javascript:;"
          class="nav-link dropdown-toggle dropdown-toggle-nocaret"
          id="dropdownBasic4"
          ngbDropdownToggle
          (click)="consultarAsuntosNotificacion()"
        >
        <span class="user-profile"><i class="ti-bell">

        </i>
        <span _ngcontent-bwe-c92="" class="badge badge-warning badge-up">{{listAsuntos.length}}</span>
        </span>

        </a>
        <ul
          ngbDropdownMenu
          aria-labelledby="dropdownBasic4"
          class="dropdown-menu dropdown-menu-right animate__animated animate__fadeInUp animate__faster"
        >
        <li *ngIf="listAsuntos.length == 0" class="dropdown-item user-details" >
            <a
            href="javaScript:;"
            >NO HAY ASUNTO POR VENCER</a>
        </li>
          <li *ngFor="let item of listAsuntos" class="dropdown-item user-details" >
            <a
            href="javaScript:;"
            style="color: black"
            (click)="visualizaAsunto(item.aS_ID)"
              >
              <div class="media" >
                <div class="avatar"></div>
                <div class="media-body displayAsunto">
                  <label style="color: black">{{item.aS_TITULO}}
                  </label>
                  <div *ngIf="item.semaforo == 'ROJO'" >
                    <span  _ngcontent-bwe-c169="" class="badge badge-danger m-1">{{item.diaS_RESTANTES + ' ' + item.leyenda}}</span>
                  </div>
                  <div *ngIf="item.semaforo == 'NARANJA'" >
                    <span  _ngcontent-bwe-c169="" class="badge SemaforoNaranja m-1">{{item.diaS_RESTANTES + ' ' + item.leyenda}}</span>
                  </div>
                  <div *ngIf="item.semaforo == 'AMARILLO'">
                    <span  _ngcontent-bwe-c169="" class="badge SemaforoAmarillo m-1" >{{item.diaS_RESTANTES + ' ' + item.leyenda}}</span>
                  </div>

                </div>
              </div>
              </a>

          </li>


        </ul>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center right-nav-link">
      <li class="nav-item border-left" ngbDropdown placement="bottom-right">
        <a
          href="javascript:;"
          class="nav-link dropdown-toggle dropdown-toggle-nocaret"
          id="dropdownBasic4"
          ngbDropdownToggle
        >
        <span class="user-profile"><i class="ti-settings"></i></span>
          <p class="ml-3 mb-0 float-right extra-small-font hidden-xs">
            {{ usuario.uS_NOMBRES + ' ' + usuario.uS_PATERNO + ' '+ usuario.uS_MATERNO }}
            <br>
            <span style="font-size: 10px;">{{usuario.nombrE_ESTRUORGANICA}}</span>
          </p>
        </a>
        <ul
          ngbDropdownMenu
          aria-labelledby="dropdownBasic4"
          class="dropdown-menu dropdown-menu-right animate__animated animate__fadeInUp animate__faster"
        >
          <li class="dropdown-item user-details">
            <a href="javaScript:;">
              <div class="media">
                <div class="avatar"></div>
                <div class="media-body">
                  <span style="color:black" >
                   <strong> {{ usuario.uS_NOMBRES + ' ' + usuario.uS_PATERNO + ' ' + usuario.uS_MATERNO }}</strong>
                  </span>
                  <br>
                  <span style="color:black">{{usuario.rol}}</span>
                </div>
              </div>
            </a>
          </li>
          <li class="dropdown-divider"></li>
          <li class="dropdown-item" (click)="signOut()">
            <i class="icon-power mr-2"></i> Cerrar sesión
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</header>
<!--End topbar header-->
