import { EnlacesModule } from './@Conade/enlaces/enlaces.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';


import { FullLayoutComponent } from "./@Common/layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./@Common/layouts/content/content-layout.component";
import { AuthGuard } from './@Common/guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'conade/autenticacion',
    pathMatch: 'full',
  },
  {
    path: 'conade', component: FullLayoutComponent,
    data: { title: 'full Views' },
    children: [
      {
        path: 'inicio',
        loadChildren: () => import('./@Common/pages/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./@Conade/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'asuntos',
        loadChildren: () => import('./@Conade/Asuntos/asuntos.module').then(m => m.AsuntosModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'usuarios',
        loadChildren: () => import('./@Conade/Usuarios/usuarios.module').then(m => m.UsuariosModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'catalogos',
        loadChildren: () => import('./@Conade/Catalogos/catalogos.module').then(m => m.CatalogosModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'enlaces',
        loadChildren: () => import('./@Conade/enlaces/enlaces.module').then(m => m.EnlacesModule),
        canActivate: [AuthGuard]

      }
    ]
  },
  {
    path: 'conade',
    component: ContentLayoutComponent,
    data: { title: 'content Views' },
    children: [
      {
        path: 'autenticacion',
        loadChildren: () => import('./@Common/auth/auth.module').then(m => m.AuthModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'content',
        loadChildren: () => import('./@Common/pages/content-pages/content-pages.module').then(m => m.ContentPagesModule),
        canActivate: [AuthGuard]

      }
    ]
  },
  { path: '**', redirectTo: 'conade/content/error-404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

