<!-- Sidebar Header starts -->
<div id="sidebar-wrapper" class="sidebar">
  <div class="brand-logo">
    <!-- <a [routerLink]="['/']"> -->
      <a (click)="toggleSidebar()"> 
      <img src="assets/images/conade4.png" width="60px" height="50px" />
    </a>
  </div>
  <div class="sidebar-header">
    <p class="mb-0" style="text-align: center;">NAVEGACIÓN</p>
  </div>
  <!-- Sidebar Header Ends -->

  <!-- Sidebar Content starts -->
  <div class="sidebar-content" [perfectScrollbar]>
    <ul id="active-class" class="navigation">
      <!-- First level menu -->
      <li
        *ngFor="let menuItem of menuItems"
        [ngClass]="[menuItem.class]"
        [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <!-- <a
          [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
          *ngIf="!menuItem.isExternalLink; else externalLinkBlock"
        > -->
        <a
          [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
          *ngIf="valido(menuItem)"
        >
        
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{ menuItem.title }}</span>
          <span
            *ngIf="menuItem.badge != ''"
            [ngClass]="[menuItem.badgeClass]"
            >{{ menuItem.badge }}</span
          >
        </a>
        <ng-template #externalLinkBlock>
          <a [href]="[menuItem.path]" target="_blank" *ngIf="valido(menuItem)">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{ menuItem.title }}</span>
            <span
              *ngIf="menuItem.badge != ''"
              [ngClass]="[menuItem.badgeClass]"
              >{{ menuItem.badge }}</span
            >
          </a>
        </ng-template>
        <!-- Second level menu -->
        <ul class="menu-content" *ngIf="menuItem.submenu.length > 0">
          <li
            *ngFor="let menuSubItem of menuItem.submenu"
            [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'"
            [ngClass]="[menuSubItem.class]"
          >
            <!-- <a
              [routerLink]="
                menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]
              "
              *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock"
            > -->
            <a
              [routerLink]="
                menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]
              "
              *ngIf="valido(menuSubItem)"
            >
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="sub-menu-title">{{ menuSubItem.title }}</span>
              <span
                *ngIf="menuSubItem.badge != ''"
                [ngClass]="[menuSubItem.badgeClass]"
                >{{ menuSubItem.badge }}</span
              >
            </a>
            <ng-template #externalSubLinkBlock>
              <a [href]="[menuSubItem.path]" *ngIf="valido(menuSubItem)">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="sub-menu-title">{{ menuSubItem.title }}</span>
                <span
                  *ngIf="menuSubItem.badge != ''"
                  [ngClass]="[menuSubItem.badgeClass]"
                  >{{ menuSubItem.badge }}</span
                >
              </a>
            </ng-template>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- Sidebar Content Ends -->
</div>
