export class NominaConadeModel {
  email?: string;
  name?: string;
  title?: string;
  responsable?: boolean;
  firmante?: boolean;
  nickname?: string;
  EmployeeId?: string;
  physicalDeliveryOfficeName?: string;
  surname?: string;
  givenName?: string;
  Guid?: string;
}


export class OFP_USUARIO {
  usroL_ID?: number;
  bovedA_USUARIO_ID?: number;
  uS_EXPEDIENTE?: number;
  uS_NOMBRE_COMPLETO?: string;
  uS_NOMBRE_COMPLETO_AREA?: string;
  uS_NOMBRE_COMPLETO_PUESTO?: string;
  uS_NOMBRES?: string;
  uS_PATERNO?: string;
  uS_MATERNO?: string;
  uS_CORREO?: string;
  uS_PUESTO?: string;
  uS_CUENTA?: string;
  tcR_ID?: number;
  tcR_TIPO?: string;
  useS_ID?: number;
  useS_ESTATUS?: string;
  useS_DESCRIPCION?: string;
  ofP_CATROLES_ID?: number;
  rol?: string;
  estruorganicA_ID?: number;
  nombrE_ESTRUORGANICA?: string;
  subdireccion?: number;
  subdireccioN_NOMBRE?: string;
  nuevo?: boolean;
  exvisor?: boolean;
  respuesta?:string;
  reL_USUARIO_TABLERO_ID?:number;
}

export class UsuarioBodevaModel {
  us_id?: number;
  us_expediente?: number;
  us_password?: string;
  us_nombres?: string;
  us_paterno?: string;
  us_materno?: string;
  us_cuenta?: string;
  us_correo?: string;
  us_puesto?: string;
  tcr_id?: number;
  uses_id?: number;
  inclusion?: string;
  envio_token?: any;
  id_area?: number;
  tipoOperacion?: string;
  nombre_completo?: string;
}
