import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RespuestaModel } from '../../models/respuesta.model';


@Injectable({
  providedIn: 'root'
})
export class MainService {



  // QA

  //gatewayAutenticacionURL = 'http://10.10.0.32:8081/'; //QA Y DEV
  /*  gatewayAutenticacionURL = 'http://10.10.0.32:8081/'; //QA Y DEV
  gatewayConfiguracionGlobalURL = 'http://10.10.0.32:8083/';
  gatewayOficialiaPartesURL = 'http://10.10.0.32:8082/';
 */


 // PROD
  gatewayNotificacion = 'http://10.10.0.29:8082/';
  gatewayJasper = 'http://10.10.0.29:8080/';
  gatewaySiarURL = 'http://10.10.0.29:8084/';

   gatewayConfiguracionGlobalURL = 'https://globalconfig.conade.gob.mx/';
  gatewayOficialiaPartesURL = 'https://sacservices.conade.gob.mx/';
  gatewayAutenticacionURL = 'https://boveda.conade.gob.mx/';

  constructor(public httpClient: HttpClient) { }
  getAsync(url: string): Promise<any> {

    return new Promise(resolve => {
      const subscription = this.httpClient.get<any>(url)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: error.error.text
            } as RespuestaModel);
          });
    });
  }

  postAsync(url: string, objeto: any): Promise<any> {

    return new Promise(resolve => {

      const subscription = this.httpClient.post(url, objeto)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { exito: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: null
            } as RespuestaModel);
          });
    });
  }

  postHeaderAsync(url: string, objeto: any, headerValue: string): Promise<any> {

    return new Promise(resolve => {

      let encabezado = new HttpHeaders();
      encabezado.append('user', headerValue);
      const subscription = this.httpClient.post(url, objeto, { headers: encabezado})
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { exito: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: null
            } as RespuestaModel);
          });
    });
  }

  putAsync(url: string, objeto: any): Promise<any> {

    return new Promise(resolve => {

      const subscription = this.httpClient.put(url, objeto)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { exito: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: null
            } as RespuestaModel);
          });
    });
  }

  deleteAsync(url: string): Promise<any> {

    return new Promise(resolve => {

      const subscription = this.httpClient.delete<any>(url)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { exito: true } as RespuestaModel);

          }, error => {

            subscription.unsubscribe();

            resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: null
            } as RespuestaModel);
          });
    });
  }


  get() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/plantilla.json', { responseType: 'text' })
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: error.error.text
            } as RespuestaModel);
          });
    });
  }

  obtenerUsuariosCSV_() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/usuarios.json', { responseType: 'text' })
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: error.error.text
            } as RespuestaModel);
          });
    });
  }

}

