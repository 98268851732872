import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { OFP_USUARIO } from '../../models/nomina-conade.model';
import { LocalStorageService } from '../../services/localstorage/localstorage.service';
import { VariablesEnum } from '../../enum/variables.enum';
import { RouteInfo } from './sidebar.metadata';
import { RolesEnum } from '../../enum/roles.enum';
import { SidebarService } from './sidebar.service';
//import { ROUTES } from '../../../../../BACKUP/src/app/@Common/template/sidebar/sidebar-routes.config';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: RouteInfo[];
  public usuarioActual: OFP_USUARIO;
  public sideBar: RouteInfo[] = [];



  constructor(
    private localStorageService: LocalStorageService,
    public sidebarservice: SidebarService,
  ) {
    }

  async ngOnInit() {

    this.usuarioActual = JSON.parse(this.localStorageService.getJsonValue(VariablesEnum.usuario_conade));

    $.getScript('./assets/js/app-sidebar.js');
    this.menuItems = ROUTES;

  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    if ($("#wrapper").hasClass("nav-collapsed")) {
      // unpin sidebar when hovered
      $("#wrapper").removeClass("nav-collapsed");
      $("#sidebar-wrapper").unbind("hover");
    } else {
      $("#wrapper").addClass("nav-collapsed");
      $("#sidebar-wrapper").hover(
        function () {
          $("#wrapper").addClass("sidebar-hovered");
        },
        function () {
          $("#wrapper").removeClass("sidebar-hovered");
        }
      )

    }
  }


 public valido(menu: RouteInfo){


      if(menu.path === '/conade/dashboard/direccion'){

        if(this.usuarioActual.reL_USUARIO_TABLERO_ID){
          return true;
        }
      }

    switch(this.usuarioActual.ofP_CATROLES_ID){
      case RolesEnum.RECEPCION:
          if(menu.recepcion){
            return true;
          } else {
            return false;
          }
        break;
        case RolesEnum.COORDINADOR_EJECUTIVO:
          if(menu.coordinador){
            return true;
          } else {
            return false;
          }
        break;
        case RolesEnum.COLABORADOR:
          if(menu.colaborador){
            return true;
          } else {
            return false;
          }
        break;
      case RolesEnum.JEFE_DEPARTAMENTO:
        if (menu.jefeDepartamento) {
          return true;
        } else {
          return false;
        }
        break;
      case RolesEnum.DIRECTOR:
        if (menu.director) {
          return true;
        } else {
          return false;
        }
        break;
      case RolesEnum.SUBDIRECTOR:
        if (menu.subdirector) {
          return true;
        } else {
          return false;
        }
        break;
      /* case RolesEnum.SUPERVISOR:
        if (menu.supervisor) {
          return true;
        } else {
          return false;
        }
      break; */
      case RolesEnum.ADMINISTRADOR:
        if (menu.admin) {
          return true;
        } else {
          return false;
        }
      break;
    }



  }


}
